@tailwind base;

html {
    scroll-snap-type: y proximity;
    scroll-behavior: 'smooth'
}

.darkscrollbar::-webkit-scrollbar {
    width: 14px;
    
}

.darkscrollbar::-webkit-scrollbar-thumb {
    
    border: 4px solid rgba(0, 0, 0, 0);
    margin-block: 50px;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 0.75);
}

.darkscrollbar::-webkit-scrollbar-thumb:hover {
    background-color:rgba(255, 255, 255, 0.5);
}

*::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
}
 

::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: rgba(17,24,39,1);
}

::-webkit-scrollbar-thumb:hover {
    background-color:rgba(17,24,39,0.5);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@tailwind components;
@tailwind utilities;

@layer base {
    h1, h2, h3, p, a {
        @apply font-mono text-left
    }
    CustomImg p {
        @apply text-center m-0
    }
    p {
        @apply text-sm mb-2 justify-start
    }
    h1 {
        @apply font-bold justify-start text-gray-900 text-[24px]
    }
    h2 {
        @apply font-bold justify-start mb-2  text-gray-900 text-[18px]
    }

}
